<template>
  <div class="login">
    用户名：<el-input @keyup.enter.native="toLogin()" v-model="form.user_name" style="margin:20px 0;"></el-input>
    密码：<el-input @keyup.enter.native="toLogin()" type="password" v-model="form.password" style="margin:20px 0;"></el-input>
    验证码：<br /><el-input @keyup.enter.native="toLogin()" v-model="form.code" style="width: 100px;margin:20px 10px 20px 0;"></el-input>
    <img @click="getImage()" :src="code" alt="">
    <div class="login-btn">
      <el-button plain type="primary" @click="toLogin()">登录</el-button>
    </div>
  </div>
</template>

<script>
import {
  login,image
} from '@/api/login';
export default {
  data() {
    return {
      form: {
        user_name: '',
        password: '',
        code: '',
        code_key: ''
      },
      code: ''
    }
  },
  mounted(){
    this.getImage()
  },
  methods: {
    toLogin () {
      login(this.form).then(res =>{
        if (res.data.msg == 'ok') {
          localStorage.setItem('token', res.data.data.token)
          localStorage.setItem('user_name', res.data.data.user_name)
          this.$router.push({path:'/'})
        } else {
          this.$message({
            type: 'error',
            message: res.data.msg
          });   
        }
      })
    },
    getImage () {
      image().then(res =>{
        this.code = res.data.data.img
        this.form.code_key = res.data.data.key
      })
    }
  }
}
</script>
<style scoped lang="scss">
  .login{
    width: 500px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 50px;
    border-radius: 6px;
  }
  .login-btn{
    text-align: center
  }
  /deep/ .el-loading-mask{
    display: none;
  }
</style>
